import Vue from "vue";
import vuei18n from "vue-i18n";
// import en from "./eng/en";
// import la from "./lao/la";
// import ch from "./ch/ch.js";
import lao from "./langs/lao";
import english from "./langs/english";
import chinese from "./langs/chinese";
Vue.use(vuei18n);
const messages = {
  lao,
  english,
  chinese,
};
export default new vuei18n({
  locale: localStorage.getItem("halang")
    ? localStorage.getItem("halang")
    : "lao",
  messages,
});
