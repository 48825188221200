import { Repositories } from "@/repositories";

const state = {
  data: [],
  loading: false,
  provinces: [],
};
const getters = {
  loading: (state) => {
    return state.loading;
  },
  branches: (state) => {
    return state.data;
  },
};
const mutations = {
  setBranchLoad(state) {
    state.loading = true;
  },
  setMaps(state, value) {
    state.data = value;
    state.loading = false;
  },
  setProvince(state, value) {
    state.provinces = value;
  },
};

const actions = {
  async getBranch({ commit }) {
    commit("setBranchLoad");
    const branches = await Repositories.getBranch();
    commit("setMaps", branches);
  },
  async province({ commit }) {
    const provinces = await Repositories.getProvince();

    commit("setProvince", provinces.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
