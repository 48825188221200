const state = {
  data: [
    {
      image: require("@/assets/images/Dialog/hal-express.svg"),
      title: "dialog_hal_exxpress_title",
      content: "dialog_hal_exxpress_content",
      name: "hal-express",
      imageTop: false,
    },
    {
      image: require("@/assets/images/Dialog/hal-vip.svg"),
      title: "dialog_hal_vip_title",
      content: "dialog_hal_vip_content",
      name: "hal-vip",
      imageTop: true,
    },
    {
      image: require("@/assets/images/Dialog/hal-speedy.svg"),
      title: "dialog_hal_speedy_title",
      content: "dialog_hal_speedy_content",
      name: "hal-speedy",
      imageTop: true,
    },
  ],
  current: {
    image: require("@/assets/images/Dialog/hal-express.svg"),
    title: "dialog_hal_exxpress_title",
    content: "dialog_hal_exxpress_content",
    imageTop: false,
  },
  isOpened: false,

  banner: {
    images: [
      require("@/assets/images/Dialog/hal-point-1.svg"),
      require("@/assets/images/Dialog/hal-point-2.svg"),
    ],
    title: "dialog_hal_point_title",
    content: "dialog_hal_point_content",
    name: "hal-point",
  },
  isBanner: false,
};
const getters = {
  loading: (state) => {
    return state.loading;
  },
};
const mutations = {
  setLoading(state, bl) {
    state.loading = bl;
  },
  setClose() {
    state.isOpened = false;
  },
  setOpened() {
    state.isOpened = true;
  },
  setData(state, value) {
    const finded = state.data.find((d) => d.name === value);
    if (value !== "hal-point") {
      state.isBanner = false;
      state.current.image = finded.image;
      state.current.title = finded.title;
      state.current.content = finded.content;
      state.current.imageTop = finded.imageTop;
    } else {
      state.isBanner = true;
    }
    state.loading = false;
  },
};

const actions = {
  async open({ commit }, { name }) {
    commit("setLoading", true);
    commit("setData", name);
    commit("setOpened");
    return true;
  },
  async close({ commit }) {
    commit("setClose");
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
