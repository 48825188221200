<template>
  <div class="error-container">
    <div class="error-content">
      <h1 class="error-title">404 Error</h1>
      <p class="error-message">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>

<style scoped>
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; /* Background color */
}

.error-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff; /* Content background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for a card-like effect */
}

.error-title {
  font-size: 2rem;
  color: #f44336; /* Red color for the title */
}

.error-message {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333; /* Text color */
}
</style>
