import axios from 'axios';

// const url_API = process.env.VUE_APP_USER_API_URL
const state = {
    users:null,
    // Loading:null
    Loading:null,
    Image_Path:null,
    url_API : process.env.VUE_APP_USER_API_URL,
    Image_API: process.env.VUE_APP_IMG_API_URL
};
const getters = {
    user_Getters:(state) =>{
        return state.Loading
    }
};
const mutations = {
    Loading_User(state){
        state.Loading = true
    },
    get_data_User(state,data){
        state.users = data

        state.Image_Path = state.Image_API
    }   
};

const actions = {
    async getData({commit}){
        await axios.get(state.url_API).then((res)=>{
            commit("Loading_User")
            // console.log(state.url_API,1111133411)
            commit("get_data_User",res.data);
        }).catch(err=>{
            if(response.status == 401){
                location.reload()
            }
            // console.log(err)
        })
    }
    ,
    async get_location({commit}){
        console.log(commit)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};