import axios from "axios";



const state = {
    Maps:null,
    Loading_maps:null,
    API_URL :process.env.VUE_APP_MAPS_API_URL
};
const getters = {
    Load_Getter:(state) =>{
        return state.Loading_maps
    },
    Maps:(state) =>{
        return state.Maps
        
    }
};
const mutations = {
    Maps_Reload(state){
        state.Loading_maps = true
    },
    set_Maps(state, value){
        state.Loading_maps = false
        state.Maps = value
    }
};

const actions = {
    async action_Maps({commit}){
        commit('Maps_Reload')
        try {
            const data = await axios.get(state.API_URL)
            commit('set_Maps',data.data)
        }catch (error) {
            console.error(error)
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};