<template>

    <img  :class="css" :src="currentImageSource" @load="onImageLoad" @error="onImageError" :alt="alt" />
</template>

<script>

export default {
  props: {
    src: String,
    alt:String,
    css: {
        type:String,
        required:false,
        default:""
    },
  },
  data() {
    return {
        // loadingSource:require("@/assets/imageError.jpg"),
        loadingSource:require("@/assets/loading2.gif"),
        // loadingSource:require("@/assets/loading.gif"),
      isLoaded: false,
    };
  },
  methods: {
    onImageLoad() {
      this.isLoaded = true;

      // console.log("Loaded")
    },
    onImageError() {
    //   console.error("Error loading image:", this.src);
    },
  },
   computed: {
    currentImageSource() {
      return this.isLoaded ? this.src : this.loadingSource;
    },
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        if (entry.isIntersecting) {
          this.isLoaded = true;
          observer.unobserve(this.$el);
        }
      });
    }, options);

    observer.observe(this.$el);
  },
};
</script>

<style scoped>
.lazy-image {
  /* Add styles for the container, e.g., width, height, or a loading animation */
  width: 100%;
  height: 100%;

}

.lazy-image img {
  /* Add styles for the loaded image */
  max-width: 100%;
  max-height: 100%;
}
</style>
