import axios from "axios";



const state = {
    // name:'sdfsdfsdfsdf',
    freight:null
};
const getters = {
    // user:(state) =>{
    //     return state.name
    // },
    freight_getter:(state) =>{
        return state.freight
    }
};
const mutations = {
    // setName(state,value){
    //     state.name = value
    // },
    set_freight(state, value){

        state.freight = value
    }
};

const actions = {
    async action_freight({commit}){
        try {
            const data = await axios.get('https://hal.hal-logistics.la/api/v1/calculate/freight/branches',)
            console.log(data.data,"set_freightset_freightset_freight")
            // commit('set_freight',data.data)
        }catch (error) {
            // alert(error)
            console.log(error)
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};