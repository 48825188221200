export async function getProvince(_axios) {
  const res = await _axios.get(`address`, {
    params: {},
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return false;
  }
}
