export async function TrackingBin(_axios, tracking_id, lang) {
  try {
    const res = await _axios.get(`v1/orders/tracking/${tracking_id}`, {
      params: {
        lang: lang,
      },
    });
    if (res.status === 200) {
      return {
        data: res.data,
        status: true,
      };
    }
  } catch (error) {
    return {
      code: error.response.status,
      status: false,
    };
  }
}
