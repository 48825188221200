export async function getPrice(_axios, params) {
  const res = await _axios.get(`v1/calculate/freight/provinces`, {
    params: params,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return false;
  }
}
