export async function getBranch(_axios){
    const res = await _axios
      .get(`v1/listing/branches`, {
        params: {
        },
      })
      if(res.status === 200){
          return res.data

      }else{
        return false
      }
}