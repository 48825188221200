import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/sweet-alert";
import "../src/assets/scss/main.scss";
// import "../src/assets/scss/global/_variables.scss"
import "@fortawesome/fontawesome-free/css/all.css";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./locales/i18n";
import LazyImage from "./components/LazyImage.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
// import loadimage from "./assets/loading.gif"

// import errorimage from "./assets/imageError.jpg"

// import VueLazyload from 'vue-lazyload'
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCgUsajH7cANXTJDpXJCT9x2n2Hj9kN0OE",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

// Loading the plugin into the Vue.
Vue.component("LazyImage", LazyImage);
Vue.component("ErrorMessage", ErrorMessage);
// .use(VueLazyload, {
//   preLoad: 1.3,
//   error: errorimage,
//   loading: loadimage,
//   attempt: 1
// });

// Vue.directive('lazyload', {
//   bind(el, binding) {
//     // el.setAttribute('data-src', binding.value);

//     el.setAttribute('data-sucess', el.src);
//     if (binding.arg === 'error') {
//       el.setAttribute('data-error', binding.expression);
//     }

//     el.setAttribute('data-loading', "/assets/loading.gif");
//     console.log(binding.arg)
//     el.classList.add('lazy-image');
//   },
//   inserted(el) {

//     console.log(55,el)
//     // el.onLoad(()=>{
//     //   console.log("Loading")
//     // })
//     el.onload = () => {
//       console.log("Image has loaded:", el.getAttribute('data-src'));
//     };
//   },
// });

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: `${process.env.VUE_APP_FIREBASE_KEY_API_URL}`,
  authDomain: `${process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_API_URL}`,
  databaseURL: `${process.env.VUE_APP_FIREBASE_DATABASEURL_API_URL}`,
  projectId: `${process.env.VUE_APP_FIREBASE_PROJECT_ID_API_URL}`,
  storageBucket: `${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET_API_URL}`,
  messagingSenderId: `${process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID_API_URL}`,
  appId: `${process.env.VUE_APP_FIREBASE_APP_ID_API_URL}`,
};
initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
