import Vue from "vue";
import Swal from "sweetalert2";

// Set SweetAlert2 configuration options
Swal.mixin({
  // Configure your desired options here
  // Add more configuration options as needed
});

// Create a global mixin to make Swal accessible from all components
Vue.mixin({
  data() {
    return {
      Swal: Swal,
    };
  },
});
