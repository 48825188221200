export async function getNews(_axios){
    const res = await _axios
      .get(`event_page`, {
        params: {
          type: "event",
        },
      })
      if(res.status === 200){
          return res.data.data

      }else{
        return false
      }
}