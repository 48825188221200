import axios from 'axios';
// import user from '../user';
if(localStorage.getItem('hal--player')){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('hal--player');
}


const state = {
    user:null,
    // Loading:null
    token:localStorage.getItem('hal--player') || null,
    Loading:null,
    Image_Path:null,
    url_API : process.env.VUE_APP_USER_API_URL,
};
const getters = {
    user_loding:(state) =>{
        
        return state.Loading
    }
    ,
    users:()=>{
        console.log('getit',state.user)
        return state.user
    },
    user_token:(state)=>{
        return state.token
    }
};
const mutations = {
    Load(state){
        state.Loading = true
        console.log('hserLoading')

    },
    get__data(state,data){
        state.user = data
        state.Loading = false
    }   
};

const actions = {
    async login({commit}){
        commit('Load')
        await axios.get(process.env.VUE_APP_USER_API_URL).then((res)=>{
            commit("get__data",res.data);
        }).catch(err=>{
            if(response.status == 401){
                location.reload()
            }
        })
    }
    ,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};