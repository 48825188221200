import axios from "axios";
import { getNews } from "./usecases/GetNews";
import { getBranch } from "./usecases/GetBranch";
import { TrackingBin } from "./usecases/Tracking";
import { getPrice } from "./usecases/Price";
import { getProvince } from "./usecases/Province";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_CONFIG,
});
class RepositoryRepo {
  async getNews() {
    return await getNews(instance);
  }
  async getBranch() {
    return await getBranch(instance);
  }
  async tracking(binId, lang) {
    return await TrackingBin(instance, binId, lang);
  }
  async getCalcPrice(params) {
    return await getPrice(instance, params);
  }
  async getProvince() {
    return await getProvince(instance);
  }
  async getCondition() {
    const res = await instance.get("contact-us-info");

    let data = {
      data: res.data ? res.data : "",
      status: res.status,
    };
    return data;
  }
}
export const Repositories = new RepositoryRepo();
