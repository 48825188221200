export default {
  // !core and navigation
  // ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ
  lao: "ລາວ",
  english: "ອັງກິດ",
  chinese: "ຈີນ",
  hal_express: "ຮຸ່ງອາລຸນຂົນສົ່ງດ່ວນ",

  hal_limited: "ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ",
  quality_award: "ລາງວັນຄຸນນະພາບ",
  news_title: "ຂ່າວສານ",
  about_us_title: "ກ່ຽວກັບພວກເຮົາ",
  contact_us_title: "ຕິດຕໍ່ພວກເຮົາ",
  service_point_title: "ຈຸດບໍລິການ",
  tacking_input: "ເລກພັດສະດຸຂົນສົ່ງຮູ່ງອາລຸນ",
  button_on_follow: "ກົດຕິດຕາມ",
  service: "ບໍລິການ",
  // on navigation
  nav_list_1: "ສ້າງບິນ",
  nav_list_2: "ເອີ້ນຮັບເຄື່ອງ",
  nav_list_3: "ໄລ່ຄ່າຂົນສົ່ງ",
  nav_list_4: "ຈຸດບໍລິການ",
  nav_list_5: "ຮັບເງິນປາຍທາງ",
  nav_list_6: "HAL POINT",
  nav_list_7: "HAL SPEEDY",
  nav_list_8: "HAL VIP",
  nav_list_9: "ຂໍ້ກຳນົດ ແລະ ເງື່ອນໄຂ",
  nav_list_10: "ຕິດຕໍ່ພວກເຮົາ",
  //end on navigation
  // footer
  footer_our_service_1: "ສ້າງບິນເອງ",
  footer_our_service_2: "ເກັບເງິນຄ່າເຄື່ອງປາຍທາງ",
  footer_our_service_3: "ເອີ້ນຮັບເຄື່ອງ",
  footer_our_service_4: "ຈຸດບໍລິການ",
  footer_our_service_5: "ຂໍ້ກຳນົດ ແລະ ເງື່ອນໄຂ",
  footer_our_service_6: "HAL POINT",
  footer_our_service_7: "HAL SPEEDY",
  footer_our_service_8: "HAL VIP",
  footer_download_app: "ດາວໂຫຼດແອັບ  ",
  // end footer
  phone: "ເບີໂທ",
  email: "ອີເມວ",
  name: "ຊື່",
  // !awards
  // 1
  award_1: "ລາງວັນ",
  title_1: "ທຸລະກິດຊັ້ນນຳດີເດັ່ນ ອຄອຊ",
  content_1: "ລາງວັນທຸລະກິດຊັ້ນນຳດີເດັ່ນ ອຄອຊ ໃນໄລຍະ <br> COVID-19 ປະຈຳປີ 2021",
  // 2
  award_2: "ລາງວັນ",
  title_2: "ASEAN BUSINESS AWARDS",
  content_2:
    "ລາງວັນປະເພດບຸລິມາສິດດີເດັ່ນ <br> ຂະແໜງຂົນສົ່ງ - ຈັດສົ່ງສິນຄ້າປະຈຳປີ 2021",
  // 3
  award_3: "ມາດຕະຖານ",
  title_3: "AN ISO 9001:2015",
  content_3: "ມາດຕະຖານການຂົນສົ່ງສາກົນ",
  // 4
  award_4: "ລາງວັນ",
  title_4: "ນັກທຸລະກິດຊັ້ນນຳດີເດັ່ນ",
  content_4: "ລາງວັນນັກທຸລະກິດຊັ້ນນຳດີເດັ່ນປະຈຳປີ  2022",
  // 5
  award_5: "ລາງວັນ",
  title_5: "ASEAN BUSINESS AWARDS",
  content_5:
    "ລາງວັນປະເພດບຸລິມາສິດດີເດັ່ນ <br> ຂະແໜງຂົນສົ່ງ - ຈັດສົ່ງສິນຄ້າປະຈຳປີ 2022",
  // contact us
  contact_us: "ຕິດຕໍ່ພວກເຮົາ",
  // news page
  news: "ຂ່າວສານ",
  type_news: "ປະເພດຂ່າວສານ",
  promotions: "ໂປຣໂມຊັ່ນ",
  hal_for_society: "Hal ເພື່ອສັງຄົມ",
  //!  home page

  // home
  download__app: "ດາວໂຫຼດເເອັບເລີຍ",
  our_service: "ບໍລິການຂອງພວກເຮົາ",
  our_service_title_1: "ສ້າງບິນເອງ",
  our_service_title_2: "COD",
  our_service_title_3: "ເອີ້ນຮັບເຄື່ອງ",
  our_service_title_4: "ຄິດໄລ່ຄ່າຂົນສົ່ງ",
  our_service_title_5: "ຈຸດບໍລິການ",
  our_service_title_6: "HAL POINT",
  our_service_title_7: "HAL SPEEDY",
  our_service_title_8: "HAL VIP",
  our_service_content_1:
    "ທ່ານສາມາດສ້າງບິນຂົນສົ່ງໄດ້ດ້ວຍຕົນເອງ ໂດຍບໍ່ຕ້ອງເສີຍເວລາໄປລໍຖ້າ",
  our_service_content_2:
    "ພວກເຮົາມີບໍລິການຮັບເງິນປາຍທາງເພື່ອເພີ່ມຊ່ອງທາງໃນການຂາຍຂອງທ່ານ",
  our_service_content_3:
    "ເຄື່ອງໜັກ ເຄື່ອງໃຫຍ່ ໄປຝາກເອງຍາກ ກົດເອີ້ນຮັບເຄື່ອງບໍ່ມີຂັ້້ນຕ່ຳ",
  our_service_content_4:
    "ທ່ານສາມາດຄິດໄລ່ຄ່າຂົນສົ່ງດ້ວຍຕົວເອງ ຊ່ວຍໃຫ້ທ່ານຄຳນວນຄ່າຂົນສົ່ງເບື່ອງຕົ້ນກ່ອນຝາກ",
  our_service_content_5:
    "ພວກເຮົາຮຸ່ງອາລຸນມີຈຸດບໍລິການທົ່ວປະເທດສາມາດເລືອກໃຊ້ບໍລິການໄດ້ທຸກສາຂາໃກ້ບ້ານທ່ານ",
  our_service_content_6:
    "ຄະແນນສະສົມຮຸ່ງອາລຸນທ່ານສາມາດນຳໃຊ້ພ້ອຍໃນການຊຳລະຄ່າຂົນສົ່ງ ຫຼື ໃຊ້ແລກສິນຄ້າທີ່ຮ່ວມລາຍການ ແລະອື່ນໆ",
  our_service_content_7:
    "ທ່ານສາມາດອັບໂຫລດລາຍການສົ່ງເຄື່ອງຈຳນວນຫຼາຍໄດ້ເປັນ Excel ເພີ່ມຄວາມສະດວກໃນການບໍລິຫານ",
  our_service_content_8:
    "ສະຫວັດດີການທີ່ທ່ານຈະໄດ້ຈາກຮຸ່ງອາລຸນ ເມື່ອເປັນລູກຄ້າວີໄອພີ",
  //! about Page
  // ບໍລິສັດ ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ
  hal_limit: "ບໍລິສັດ ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ",
  hal_limit_content:
    "ສ້າງຕັ້ງໃນປີ 2018 ມາຈາກ 2 ບໍລິສັດໃນເຄືອຄື:ບໍລິສັດຮຸ່ງອາລຸນຊິບປິ້ງທີ່ໄດ້ດໍາເນີນທຸລະກິດກ່ຽວກັບການນໍາເຂົ້າ - ສົ່ງອອກສິນຄ້າຜ່ານເເດນ ແລະ ບໍລິສັດ ຮຸ່ງອາລຸນ ຂົນສົ່ງດ່ວນທີ່ ປະກອບທຸລະກິດກ່ຽວກັບການຂົນສົ່ງຮັບຈັດສົ່ງພັດສະດຸທົ່ວປະເທດ ບໍລິສັດ ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດ ຖືກສ້າງຕັ້ງຂຶ້ນຢ່າງເປັນທາງການໃນວັນທີ 29 ມັງກອນ 2019, ປັດຈຸບັນ ຮຸ່ງອາລຸນ ໂລຈິສຕິກ ຈຳກັດມີພະນັກງານ ທັງໝົດ 1,121 ຄົນ. ມີສໍານັກງານໃຫຍ່ຢູ່ນະຄອນຫຼວງວຽງຈັນ ແລະ ມີສາຂາ ທັງໝົດ 507 ສາຂາ, ເຊິ່ງປະກອບມີ ນະຄອນຫຼວງ 169 ສາຂາ ສາຂາຫຼັກ 93 ແລະ ສາຂາຕົວແທນ 397.",
  // vision
  vision: "ວິໃສທັດ",
  vision_content_1:
    "ເປັນຜູ້ນຳດ້ານນະວັດຕະກຳການຂົນສົ່ງເພື່ອຕອບໂຈດ ແລະ ຮອງຮັບອີຄອມເມີສ  (E -Commerce) ຫຼື ການຄ້າທາງອີເລັກໂທຣນິກບໍ່ມີພົມແດນທີ່ກໍາລັງເຕີບໃຫຍ່ ໄດ້ແບບຄົບວົງຈອນ.",

  vision_content_2:
    "ນຳໃຊ້ເຕັກໂນໂລຊີທີ່ທັນສະໄໝເຂົ້າໃນການນຳໃຊ້ເຕັກໂນໂລຊີເຂົ້າໃນການປະຕິບັດງານ.",
  vision_content_3: "ຫັນສູ່ບັນດາປະເທດພາກພື້ນໃນການຂະຫຍາຍທຸລະກິດ.",
  vision_content_4: "ປະຍຸກ ແລະປັບໂຕໃຫ້ເຂົ້າກັບເສັ້ນທາງລົດໄຟ.",
  vision_content_5: "ສົ່ງເສີມການສົ່ງອອກພາຍໃນ.",
  // mission
  mission: "ພັນທະກິດ",
  mission_content_1:
    "ເຮັດໃຫ້ການຝາກເຄື່ອງຫຼືການຄ້າຂາຍຂອງປະຊາຊົນທຸກສາຂາອາຊີບໃຫ້ສະດວກສະບາຍ.",

  mission_content_2:
    "ຊ່ວຍໃຫ້ສິນຄ້າພື້ນເມືອງ (One District One Product - ODOP) ສາມາດກະຈາຍອອກຫາຕະຫລາດທີ່ກ້ວາງຂຶ້ນ ກ້າວໄປເຖິງການສົ່ງອອກແລະເຮັດໃຫ້ຮອບໝູນວຽນຂອງເງິນທຶນມີປະສິດທິພາບສູງຂຶ້ນ.",

  mission_content_3:
    "ເປັນສ່ວນໜຶ່ງທີ່ນ້ອຍໆໃນການກະຕຸ້ນເສດຖະກິດພາຍໃນປະເທດໃຫ້ດີຂຶ້ນເທື່ອ ລະກ້າວ.",
  // purpose
  purpose: "ຈຸດປະສົງ",
  purpose_content_1: "Hal Express ເພີ່ມສາຂາໃຫ້ແກ່ທຸກໆແຂວງໃນປະເທດ.",
  purpose_content_2: "ແຜນການຂະຫຍາຍຈຸດບໍລິການໄປສູ່ເຂດເມືອງ.",
  purpose_content_3:
    "ການວາງແຜນຂະຫຍາຍທຸລະກິດໄປສູ່ການບໍລິການສາກົນກ່ອນອື່ນໝົດແມ່ນຈີນ, ໄທ ແລະ ຫວຽດນາມ.",

  purpose_content_4: "ລະບົບສາງເກັບຍັກໃຫຍ່ເພື່ອຮັບໃຊ້ລູກຄ້າ.",
  // most popular question
  popular_questions: "ຄຳຖາມຍອດນິຍົມ",
  popular_questions_title_1: "1)	ຢາກສະໝັກ COD?",
  popular_questions_title_2: "2)	ສົນໃຈເປີດສາຂາແຟນຊາຍ?",
  popular_questions_title_3: "3)	ກວດບີນໃຫ້ແດ່ເຄື່ອງຍັງບໍ່ຮອດສາຂາ?",
  popular_questions_title_4: "4)	ກວດບີນໃຫ້ແດ່ໄປຮັບເຄື່ອງແຕ່ຍັງບໍ່ກົດສໍາເລັດບີນ?",
  popular_questions_title_5:
    "5)	HAL POINT ສາມາດຊື້ຫຍັງໄດ້ແດ່ ສາມາດໂອນໃຫ້ກັນໄດ້ບໍ ສາມາດເຕີມເງີນໄດ້ບໍ?",
  popular_questions_title_6: "6)	ແນະນໍາດາວໂຫລດແອັບເປັນຫຍັງຄືບໍ່ໄດ້ພ້ອຍ?",
  popular_questions_title_7:
    "7)	ກວດໃຫ້ແດ່ມີການແຈ້ງເຕືອນວ່າໄດ້ຮັບເງີນ COD ແຕ່ຍັງບໍ່ເຫັນເງີນເຂົ້າ?",
  popular_questions_content_1:
    "ລົບກວນສອບຖາມແດ່ເຈົ້າ ລູກຄ້າມີແອັບຮຸ່ງອາລຸນແລ້ວບໍ່ເຈົ້າ ຖ້າກໍລະນີຍັງບໍ່ມີແອັບແນະນໍາລູກຄ້າດາວໂຫລດແອັບກ່ອນເດີເຈົ້າ ແລ້ວຈື່ງສາມາດສະໝັກໄດ້ເຈົ້າ",

  popular_questions_content_2:
    "ສະບາຍດີ ບໍລິສັດຮຸ່ງອາລຸນຂົນສົ່ງເຈົ້າ ແນະນໍາລູກຄ້າສອບຖາມຂໍ້ມູນເພີ່ມເຕີມ.......",
  popular_questions_content_3:
    "ໂດຍຂໍຂໍ້ມູນບິນຝາກເຄື່ອງແນ່ເຈົ້າ ດຽວແອັດກວດສະຖານະເຄື່ອງໃຫ້ເຈົ້າ",
  popular_questions_content_4:
    "ໂດຍຂໍຂໍ້ມູນບິນຝາກເຄື່ອງແນ່ເຈົ້າ ດຽວແອັດແຈ້ງທາງສາຂາກວດຄືນໃຫ້ເຈົ້າ",
  popular_questions_content_5: "ສະບາຍດີ ບໍລິສັດຮຸ່ງອາລຸນຂົນສົ່ງເຈົ້າ",
  popular_questions_content_5_list_1:
    "ພ້ອຍສາມາດແລກສີນຄ້າຕາມຮ້ານຄ້າທີ່ມີຮ່ວມລາຍການໃນແອັບໄດ້ເຈົ້າ",
  popular_questions_content_5_list_2:
    "ພ້ອຍຍັງບໍ່ສາມາດໂອນໃຫ້ກັນໄດ້ເຈົ້າ ມີແຕ່ສາມາດແລກສິນຄ້າ ແລະ ເຕີມມູນຄ່າໂທໄດ້ເຈົ້າ, ສາມາດໃຊ້ເຕີ່ມນໍ້າມັນລົດຕາມປໍ້າ Plus ທີ່ມີຮ່ວມລາຍການ ແລະ ໃຊ້ຊຳລະຢູ່ສູນການຄ້າ J-Mart",

  popular_questions_content_6:
    "ສະບາຍດີ ບໍລິສດຮຸ່ງອາລຸນຂົນສົ່ງ ຕ້ອງຂໍໂທດຫຼາຍໆເຈົ້າຕອນນີ້ໝົດໂປຮໂມຊັນລະເດີເຈົ້າການແນະນໍາໝູ່ດາວໂຫລດແອັບ",
  popular_questions_content_7:
    "ສະບາຍດີ ຕ້ອງຂໍໂທດນໍາລູກຄ້າຫຼາຍໆເຈົ້າ ລະບົບແຈ້ງເຕືອນກ່ອນແລ້ວເງີນຈະເຂົ້າຕາມຫຼັງເຈົ້າ ລົບກວນລໍຖ້າກ່ອນເຈົ້າ ຫຼື ສອບຖາມເພີ່ມເຕີ່ມໜ່ວຍງານ COD",

  //! contact page
  contact_hotline: "ສາຍດ່ວນ",
  contact_location_title: "ສະຖານທີ່",
  contact_location_content: "ບ້ານຈອມມະນີ ເມືອງໄຊເສດຖາ ນະຄອນຫຼວງວຽງຈັນ",

  contact_send_us_message: "ສົ່ງຂໍ້ຄວາມຫາພວກເຮົາ",
  contact_send_us_message_detail:
    "ຕິດຕໍ່ຫາພວກເຮົາເມື່ອທ່ານຕ້ອງການສອບຖາມຂໍ້ມູນເພີ່ມເຕີມ.",
  contact_input_topic: "ຫົວຂໍ້",
  contact_input_message: "ຂໍ້ຄວາມ",
  contact_button_send_message: "ສົ່ງຂໍ້ຄວາມ",
  contact_follow_at: "ຕິດຕາມພວກເຮົາໄດ້ທີ່",
  //! news page
  news_type: "ປະເພດຂ່າວສານ",
  news_type_title_1: "ຂ່າວສານ",
  news_type_title_2: "ໂປຣໂມຊັ່ນ",
  news_type_title_3: "HAL ເພື່ອສັງຄົມ",
  //! Branch
  nearest_branches: "ສາຂາໃກ້ຄຽງ",
  search_branches: "ຄົນຫາສາຂາ",
  search_all_branches: "ຄົນຫາສາຄາທັງໝົດ",
  chose_province: "ເລືອກແຂວງ",
  chose_district: "ເລືອກເມືອງ",
  not_found: "ບໍ່ມີສາຂາດັ່ງກ່າວ",
  // ! dialog
  dialog_hal_exxpress_title: "ບໍລິການ ເອີ້ນຮັບເຄື່ອງ",
  dialog_hal_exxpress_content:
    "ການນຳໃຊ້ບໍລິການເອີ້ນຮັບເຄື່ອງ <br>ລູກ​ຄ້າ​ຕ້ອງ​ຕິດ​ຕັ້ງ ພ້ອມ​ລົງ​ທະ​ບຽນ​ແອັບ HAL EXPRESS",
  dialog_hal_vip_title: "ບໍລິການ HAL VIP",
  dialog_hal_vip_content: "ບໍລິການ HAL VIP ຈະເປີດໃຫ້ທ່ານນຳໃຊ້ໃນໄວໆນີ້!",
  dialog_hal_speedy_title: "ບໍລິການ HAL SPEEDY",
  dialog_hal_speedy_content: "ບໍລິການ HAL SPEEDY ຈະເປີດໃຫ້ທ່ານນຳໃຊ້ໃນໄວໆນີ້!",
  dialog_hal_point_title: "ບໍລິການ HAL POINT",
  dialog_hal_point_content:
    "ເມື່ອໄດ້ຮັບພ້ອຍຈາກຮຸ່ງອາລຸນ ທ່ານສາມາດນຳໃຊ້ພ້ອຍໃນການແລກສິນຄ້າ ຫຼື ຄ່າບໍລິການຂອງຮຸ່ງອາລຸນໄດ້",

  // !calc
  calc_title: "ຄິດໄລ່ຄ່າບໍລິການ",
  calc_start_branch: "ສາຂາຕົ້ນທາງ",
  calc_end_branch: "ສາຂາປາຍທາງ",
  calc_start_branch_choose: "ເລືອກຕົ້ນທາງ",
  calc_end_branch_chose: "ສາຂາປາຍທາງ",
  calc_width: "ລວງກວ້າງ",
  calc_height: "ລວງສູງ",
  calc_length: "ລວງຍາວ",
  calc_weight: "ໜ້ຳໜັກ",
  calc_input_width: "ປ້ອນລວງກວ້າງ",
  calc_input_height: "ປ້ອນລວງສູງ",
  calc_input_length: "ປ້ອນລວງຍາວ",
  calc_input_weight: "ນ້ຳໜັກຂອງພັດສະດຸ",
  calc_kip: "Kip",
  calc_price: "ລາຄາສິນຄ້າ",
  //   benfit
  // Benefits of membership 会员权益
  benfit_head: "ສິດປະໂຫຍດເມື່ອທ່ານເປັນສະມາຊິກ",
  // ລະບົບຈັດການຂົນສົ່ງ Shipment management 装运管理
  benfit_title_1: "ລະບົບຈັດການຂົນສົ່ງ",
  //ສະສົມຄະແນນ Collect point 积分收集点
  benfit_title_2: "ສະສົມຄະແນນ",
  // ທ່ານສາມາດໃຊ້ບໍລິການຕ່າງໆບໍວ່າຈະເປັນຝາກເຄື່ອງເອງ You can create order, use COD service and request pickup 您可以创建订单，使用代货收款服务和请求提货
  benfit_content_1:
    "ທ່ານສາມາດໃຊ້ບໍລິການຕ່າງໆບໍວ່າຈະເປັນຝາກເຄື່ອງເອງ, COD,ເອີ້ນລົດຮັບເຄື່ອງ ແລະ ບໍລິການອື່ນໆ",
  // ເມື່ອທ່ານໃຊ້ບໍລິການຝາກເຄື່ອງ,ຮັບເຄື່ອງ ທ່ານຈະໄດ້ຮັບຄະແນນສະສົມເພື່ອແລກສິດ ພິເສດ Once you use our service, you will receive our points 一旦您使用我们的服务，您将获得我们的积分
  benfit_content_2:
    "ເມື່ອທ່ານໃຊ້ບໍລິການຝາກເຄື່ອງ,ຮັບເຄື່ອງ ທ່ານຈະໄດ້ຮັບຄະແນນສະສົມເພື່ອແລກສິດ ພິເສດ",

  // ລົງທະບຽນ Register 注册
  header: "ລົງທະບຽນ",
  login: "ເຂົ້າສູ່ລະບົບ",
  register: "ລົງທະບຽນ",
  // ເພື່ອໃຊ້ບໍລິການຂອງ Hal Express To use HAL Express service 使用红阿伦快递公司服务
  header__Content: "ເພື່ອໃຊ້ບໍລິການຂອງ",
  // ຍິນດີຕ້ອນຮັບ Welcome 欢迎光临
  title: "Welcome",
  // ເຂົ້າສູ່ລະບົບ ເພື່ອຮັບສິດໃນການໃຊ້ ບໍລິການຕ່າງໆ ຂອງ HAL Expressແລະ ສິດທິໃນການສະສົມຄະແນນເພື່ອຮັບຂອງລາງວັນຕ່າງໆ Login to use our services and claim a lot of prizes 进入红阿伦快递软件已使用 您就可以获得积分，更多的礼物
  title__content: "ເຂົ້າສູ່ລະບົບ ເພື່ອຮັບສິດໃນການໃຊ້ ບໍລິການຕ່າງໆ",
  // --------card------

  place__name: "ຊື່",

  // ນາມສະກຸນ Surname 姓
  last: "ນາມສະກຸນ",
  place__last: "ນາມສະກຸນ",
  // ວັນເດືອນປີເກີດ Date of birth 出生日期
  dob: "ວັນເດືອນປີເກີດ",
  // Mobile number 电话号码
  phone__number: "ເບີໂທລະສັບ",
  place__number: "01234567",
  // Password 验证密码
  pass: "ລະຫັດຜ່ານ",
  place__pass: "ລະຫັດຜ່ານ",
  // Gender 性别
  gender: "ເພດ",
  // Male 男
  male: "ຊາຍ",
  // Female 女
  female: "ຍິງ",
  // successful 成功的
  btn: "Successful",
  // ຍອມຮັບເງື່ອນໄຂ ແລະ ຂໍ້ກຳນົດ  Accept conditions and policies 接受各个条件和禁止
  remark: "ຍອມຮັບເງື່ອນໄຂ ແລະ ຂໍ້ກຳນົດ  ",
  // ອ່ານເພີ່ມເຕີມ Read more 阅读更多
  readMore: "ອ່ານເພີ່ມເຕີມ",
  // OTP box confirm 确认
  otp__title: "ຍືນຍັນ ",
  otp__head: "",
  otp__token_end: "",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  otp__callagain: "ກົດເພື່ອສົ່ງອີກຄັ້ງ",
  // ກະລຸນາໃສ່ລະຫັດ ທີ່ທ່ານໄດ້ຮັບທາງຂໍ້ຄວາມ Please enter the Password sent to your SMS 请输入您手机收到的短信验证码
  otp__getInbox: "ກະລຸນາໃສ່ລະຫັດ ທີ່ທ່ານໄດ້ຮັບທາງຂໍ້ຄວາມ",
  // ຫາກທ່ານຍັງບໍ່ໄດ້ຮັບຂໍ້ຄວາມ If you do not receive message 如果您没有收到信息
  otp__Ifnotget: "ຫາກທ່ານຍັງບໍ່ໄດ້ຮັບຂໍ້ຄວາມ",
  // ກົດເພື່ອສົ່ງອີກຄັ້ງ Please try again 再次发送
  click_tosend: "ກົດເພື່ອສົ່ງອີກຄັ້ງ",
  // OPT ERR
  // ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ Confirmed Password not correct 验证码错误
  otp_err: "ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ",
  //
  // validation
  // ຕ້ອງລະບຸຊື່ Please input your name 请填写名
  validate_name: "ຕ້ອງລະບຸຊື່",
  // ຕ້ອງລະບຸນາມສະກຸນ Please input your surname 请填写姓
  validate_lastname: "ຕ້ອງລະບຸນາມສະກຸນ",
  // ຕ້ອງລະບຸເບີໂທ Phone can't be empty 请填写电话号码
  validate_phone: `ຕ້ອງລະບຸເບີໂທ`,
  // Phone must be 8 digits 电话号码必须是8位数字
  validate_phoneLength: "Phone must be 8 digits",
  // ຕ້ອງລະບຸລະຫັດຜ່ານ  Please input Password 请输入密码
  validate_pass: "ຕ້ອງລະບຸລະຫັດຜ່ານ",
  // gender ---Please pick gender 请选择性别
  validate_gender: "Please pick gender",
  // ລະຫັດຜ່ານຢ່າງນ້ອຍ 6 ຫຼັກ Password must be more than 6 digits 密码不可少于6位数
  validate_passLength: "ລະຫັດຜ່ານຢ່າງນ້ອຍ 6 ຫຼັກ",
  // ຕ້ອງຍອມຮັບເງື່ອນໄຂກ່ອນ Please accept the conditions 只有接受条件才能继续
  validate_accept: "ຕ້ອງຍອມຮັບເງື່ອນໄຂກ່ອນ",
  // ເບີນີ້ມີຢູ່ໃນລະບົບແລ້ວ ກະລຸນາເຂົ້າສູ່ລະບົບ This number has been already registered, please log in 该电话号码已经注册过，请直接登录
  haveAnumber: "ເບີນີ້ມີຢູ່ໃນລະບົບແລ້ວ ກະລຸນາເຂົ້າສູ່ລະບົບ",
  // ເບີນີ້ຂໍເກີນຈໍານວນຄັ້ງ ກະລຸນາລອງໃຫມ່ພາຍຫລັງ 4 ຊົ່ວໂມງ Too many requests, please try again in 4 hours 登录次数过多，请等4个小时后再操作
  limit: "ເບີນີ້ຂໍເກີນຈໍານວນຄັ້ງ ກະລຸນາລອງໃຫມ່ພາຍຫລັງ 4 ຊົ່ວໂມງ",
};
