import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import gmap from "./modules/gmap.js";
import user from "./modules/user.js";
import hser__login from "./modules/hser/login";
import freight from "./modules/freight.js";
import halang from "./modules/hal__langue/hal__lng";
import dialog from "./modules/Dialog.js";
// old
import tracking from "./modules/Tracking.js";
// import tracking from './modules/hooks/tracking.js'
import branches from "./modules/branches.js";

export default new Vuex.Store({
  modules: {
    gmap,
    user,
    dialog,
    freight,
    hser__login,
    halang,
    tracking,
    branch: branches,
  },
});
