<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    const halang = localStorage.getItem("halang");

    if (halang == null) {
      localStorage.setItem("halang", "lao");
    }
    if (halang !== "lao" && halang !== "english" && halang !== "chinese") {
      localStorage.setItem("halang", "lao");
      window.location.reload();
    }
    this.$store.state.halang["langue"] = localStorage.getItem("halang");
  },
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
