import Vue from "vue";
import VueRouter from "vue-router";
// import hal_maps from "../views/Admin/new_map/hal_maps.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Layouts/default.vue"),
    meta: { hal_player: false },

    children: [
      // ຫົວຂໍ້ທັງໝົດກ່ຽວກັບ navbar service
      {
        path: "/",
        name: "home.page",
        component: () => import("../views/pages/HomePage.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/branch",
        name: "branch.page",
        component: () => import("../views/pages/BranchPreviewPage.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/calc",
        name: "calculate.page",
        component: () => import("../views/pages/CalculatePage.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/tracking/:bin",
        name: "deposit.page",
        component: () => import("../views/pages/DepositeBinPage.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/news",
        name: "news.page",
        component: () => import("../views/pages/NewsPage.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/about-us",
        name: "about.page",
        component: () => import("../views/pages/AbouutPage.vue"),
        meta: { hal_player: false },
      },

      // {
      //   path: "/",
      //   name: "guestHome",
      //   component: () => import("../views/Guest/Home/guestHome.vue"),
      //   meta:{hal_player:false}
      // },
      {
        path: "/contact",
        name: "contact.page",
        component: () => import("../views/pages/ContactView.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/codition-hal-express",
        name: "condition.page",
        component: () => import("../views/pages/ConditionPage.vue"),
        meta: { hal_player: false },
      },
      // {
      //   path: "/gdeposit",
      //   name: "guest_deposit",
      //   component: () => import("../views/Guest/Home/guest_Deposit.vue"),
      //   meta:{hal_player:false},
      // },
      // {
      //   path: "/gdepositbin",
      //   name: "guestDeposit__bin",
      //   component: () => import("../views/Guest/serviceView/guestDeposit__bin.vue"),
      //   meta:{hal_player:false},
      // },
      // {
      //   path: "/gcalc",
      //   name: "guest_calc",
      //   component: () => import("../views/Guest/Home/guest_calc.vue"),
      //   meta:{hal_player:false},
      // },
      // {
      //   path: "/gtracking",
      //   name: "guest_tracking",
      //   component: () => import("../views/Guest/Home/guest_tracking.vue"),
      //   meta: { hal_player: false },
      //   //   // tracking params
      // },
      // {
      //   path: "/gtrackingdetail/:VTE",
      //   name: "guest_tracking_detail",
      //   component: () => import("../views/Guest/Home/guest_tracking_detail.vue"),
      //   meta:{hal_player:false},
      //   // tracking params

      // },
      // user login register
      {
        path: "/gregister",
        name: "guestRegister",
        component: () => import("../views/Guest/Auth/guestRegister.vue"),
        meta: { hal_player: false },
      },
      // readme
      {
        path: "/prescription",
        name: "guestReadme",
        component: () => import("../views/Guest/Auth/guestReadme.vue"),
        meta: { hal_player: false },
      },
      {
        path: "/glogin",
        name: "guestLogin",
        component: () => import("../views/Guest/Auth/guestLogin.vue"),
        meta: { hal_player: false },
      },
      // {
      //   path: "/gmap",
      //   name: "guest_maps",
      //   component: () => import("../views/Guest/Home/guest_maps.vue"),
      //   meta: { hal_player: false },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
