import { Repositories } from "@/repositories";

const state = {
  data: {
    shipment_info: {},
    tracking_events: [],
  },
  loading: false,
  isNotFound: false,
  isError: false,
  binId: "",
};
const getters = {
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.isError;
  },
  shipmentInfo: (state) => {
    return state.data.shipment_info;
  },
  trackingEvents: (state) => {
    return state.data.tracking_events;
  },
};
const mutations = {
  setLoading(state, bl) {
    state.loading = bl;
  },
  setData(state, value) {
    state.data.shipment_info = value.shipment_info;
    state.data.tracking_events = value.tracking_events;

    state.loading = false;
  },
  setNotFound(state, bl) {
    state.isNotFound = bl;
  },
  setError(state, bl) {
    state.isError = bl;
  },
  setBinId(state, value) {
    state.binId = value;
  },
};

const actions = {
  async tracking({ commit }, { languaue, binId }) {
    commit("setLoading", true);
    commit("setNotFound", false);
    commit("setError", false);
    const tracking = await Repositories.tracking(binId, languaue);
    if (!tracking.status) {
      commit("setNotFound", true);
      commit("setError", true);
      commit("setLoading", false);

      return false;
    }
    commit("setData", tracking.data);
    commit("setBinId", binId);
    commit("setLoading", false);
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
